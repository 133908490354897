<template>
	<div class="cbox">
		<div class="headbar">
			<div style="float: right;">
				<el-form :inline="true" :model="queryForm" style="">
					<el-form-item label="名称" min-width="120">
						<el-input size="small" v-model="queryForm.keyword" placeholder="请输入关键字"></el-input>
					</el-form-item>


					<!-- <el-form-item label="栏目分类" min-width="120">
						<el-select size="small" v-model="queryForm.type_name" style="width: 180px;" placeholder="请选择分类">
							<el-option v-for="item in TypeList" :key="item.id" :label="item.notify_type"
								:value="item.notify_type">
							</el-option>
						</el-select>
					</el-form-item> -->
					<el-form-item label="发布时间" min-width="120">
						<el-date-picker size="small" v-model="queryForm.date" style="width: 180px;" placeholder="请选择日期"
							value-format="yyyy-MM-dd"></el-date-picker>
					</el-form-item>


					<el-form-item>
						<el-button size="small" type="primary" @click="search">搜索</el-button>
						<el-button v-if="canAdd" size="small" type="primary" icon="el-icon-plus" @click="add">发公告
						</el-button>

					</el-form-item>
				</el-form>
			</div>
			<div style="padding-top: 14px;">
				<el-breadcrumb>
					<el-breadcrumb-item>公告</el-breadcrumb-item>
					<el-breadcrumb-item>公告列表</el-breadcrumb-item>

				</el-breadcrumb>
			</div>

		</div>
		<div class="bcontent">
			<el-table stripe :data="DataList" style="width: 100%;margin-bottom: 20px; color: #000;" border size="mini">
				<el-table-column type="index" label="编号" width="80">
				</el-table-column>
				<el-table-column prop="cover" label="封面" width="120">
					<template slot-scope="scope">
						<div style="width: 60px; height: 50px; overflow: hidden;">
							<img :src="scope.row.image" style="width: 60px; vertical-align: middle;"
								onclick="viewImage(this)" />
						</div>

					</template>
				</el-table-column>
				<el-table-column prop="name" label="标题" min-width="230">
					<template slot-scope="scope">
						{{scope.row.title}}
					</template>
				</el-table-column>
				<el-table-column prop="type_name" label="类型" width="120">
				</el-table-column>
				<!-- <el-table-column prop="alert" label="弹窗" width="120">
					<template slot-scope="scope">
						{{scope.row.alert?'弹窗公告':''}}
					</template>
					</el-table-column> -->
				
				<el-table-column prop="create_user_name" label="发布人" width="120">

				</el-table-column>


				<el-table-column prop="id" label="日期" width="180">
					<template slot-scope="scope">
						{{scope.row.cdate}}

					</template>
				</el-table-column>

				<el-table-column prop="status" label="状态" width="120">
					<template slot-scope="scope">
						<el-tag size="mini" style="color: orangered;" v-if="scope.row.status==0">草稿</el-tag>
						<el-tag size="mini" style="color: seagreen;" v-if="scope.row.status==1">已发布</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="readcount" label="阅读数" width="70">
					<template slot-scope="scope">
						<span>{{scope.row.read_count?scope.row.read_count:0}}</span>
						<!-- {{scope.row.user_count}} -->

					</template>
				</el-table-column>

				<el-table-column fixed="right" label="操作" width="150">
					<template slot-scope="scope">

						<el-button type="text" @click="viewInfo(scope.row)">查看</el-button>

						<!-- <el-button type="text" @click="viewDetail(scope.row)">统计</el-button> -->
						<el-button v-if="scope.row.uid==$store.state.teacher.id" type="text"
							@click="editItem(scope.row)">
							编辑</el-button>
						<el-button v-if="scope.row.uid==$store.state.teacher.id" type="text"
							@click="deleteItem(scope.row)">删除</el-button>

					</template>
				</el-table-column>
			</el-table>

			<div style="height: 20px;">

			</div>


		</div>
		<div
			style="position: absolute; bottom: 0; height: 40px; width: 100%; text-align: right; background-color: #fff; z-index: 20;">
			<el-pagination @current-change="paginate" :current-page="Page.current_page"
				:page-size="Page.per_page" :total="Page.count" layout="total, prev, pager, next">
			</el-pagination>
		</div>

		<phone v-if="showPhone" :id="EditItem.id"></phone>
		<tongji v-if="showTongji" :id="EditItem.id"></tongji>
	</div>
</template>

<script>
	import phone from "./articleinfo"
	import tongji from "./tongji"
	export default {
		components: {
			phone,
			tongji
		},
		data() {
			return {
				temphead: "../../../static/temphead.jpg",
				queryForm: {
					type: "title",
					keyword: "",
					dept_id: "",
					type_name: ""
				},
				activeName: "first",
				DataList: [],
				dialogVisible: false,
				showTongji: false,
				Page: {
					current_page: 1,
					next_page: 2,
					pre_page: 10,
					per_page: 10,
					total_page: 0,
					count: 0
				},
				product_catalog: [],
				userinfo: {},
				datainfo: [],
				read_teacher: [],
				read_teacher_num: 0,
				read_no_teacher: [],
				read_no_teacher_num: 0,
				read_student: [],
				detailTitle: "",
				EditItem: true,
				//按钮权限
				auth: {
					edit: false,
					delete: false
				},
				TypeList: [],
				DeptData: [],
				showPhone: false,
				canAdd: false,
				canEdit: false

			}
		},

		mounted() {

			setTimeout(() => {
				console.log(this.$store.state)
			}, 2000);

			this.getTypeList();
			this.search()

			this.getMenuAuth()



		},
		methods: {

			getMenuAuth() {//检查是否有发公告的权限
				this.$http.post("/api/getUserMenu").then(res => {
					for (let b of res.data) {
						for (let ss of b.children) {
							for (let s of ss.children) {
							if (s.path == "/notify") {
								for (let btn of s.children) {
									if (btn.mtype == "button" && btn.path == "create") {
										this.canAdd = true
									}
								}
							}
						}
						}
					}
				})
			},
			getTypeList: async function() {


				this.$http.post("/api/notify_type_list").then(
					res => {
						this.TypeList = res.data.data;
					})

			},

			paginate(val) {
				this.Page.current_page = val;
				this.query(val)
			},
			search: function() {
				this.query(1);
			},
			query(page) {

				this.$http.post('/api/notify_list_list', {
					type_name: this.queryForm.type_name,
					page: this.Page.current_page,
					pagesize: this.Page.pre_page,
					title: this.queryForm.keyword,
					cdate: this.queryForm.date


				}).then(res => {
					this.DataList = res.data.data;
					this.Page = res.data.page
					this.Page.pre_page = 10
					this.Page.per_page = 10


				});
			},
			update(item) {

				sessionStorage.setItem("product", JSON.stringify(item))

				this.$router.push("/proedit?" + item.id)
			},
			add() {
				this.$router.push("/notifyedit")
			},
			deleteItem(item) {
				this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post("/api/notify_delete", {
						id: item.id
					}).then(res => {
						this.$message({
							type: 'success',
							message: '删除成功'
						});

						this.query();
					})

				}).catch(() => {

				});
			},


			viewInfo(item) {

				this.EditItem = item
				this.showPhone = true
				// this.$http.post("/api/article_info", {
				// 	id: item.id,

				// }).then(res => {

				// })
			},

			editItem(item) {
				this.$router.push("/notifyedit?id=" + item.id)
			},
			setTop(item) {
				this.$http.post("/api/article_top", {
					id: item.id,
					top: item.top ? 0 : 1
				}).then(res => {
					this.$message({
						type: 'success',
						message: '置顶成功'
					});

					this.query();
				})
			},

			closePhone() {
				this.showPhone = false
				this.showTongji = false
			},
			viewDetail(e) {
				this.EditItem = e
				this.showTongji = true
			}




		}
	}
</script>
<style scoped type="text/less" lang="less">
	.pageContainer {
    height: 100%;
}

.product-status {
    padding-bottom: 10px;
    border-bottom: 5px solid #e8e8e8;
}

.arttile {
    font-size: 18px;
    color: #333333;
    padding: 20px 0;
}

.typeitem {
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0px 10px;
}

.contentcontent {
    padding: 20px 0;
}

.attaches {
    padding: 20px 0;
}

.attitem {
    color: #0785D1;
    cursor: pointer;
}

.useritem {
    width: 200px;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 5px;
    margin-right: 5px;
    border: 1px solid #f6f6f6;
    padding: 5px;
}

.useritem image {
    vertical-align: middle;
}
</style>
